/**
 * Order is very important.
 * 1st: SCSS variable overrides
 * 2nd: build import
 * 3rd: component hooks
 * 4th: component import
 * 5th: CSS variables declaration/override (build includes :root level declaration)
 * 6th: other custom CSS
 */

// font
//@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Varela%20Round:wght@200;300;400;500;600;700;800&display=swap');

// alternative font: alef
//@import url('https://fonts.googleapis.com/css2?family=Alef:wght@200;300;400;500;600;700;800&display=swap');

//@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap');

//@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
// 1st: SCSS variable overrides
$global-prefix: "";

$button-height: 2.25em !default;
$button-padding-horizontal: 1.2em !default;
$button-padding-horizontal-large: 1.4em !default;
$button-padding-horizontal-slim: 0.2em !default;
$button-border-thickness: 1px !default;

//$button-height-small             : 2.0em !default;
//$button-padding-horizontal-small : 1.0em !default;

//$button-height-large             : 2.4em !default;

// field element selector
@mixin hook-form-field-element {
  padding: 0.3em 2em;

  &--s {
    flex: 100%;
    width: 100%;
    padding: 0.2em 2em;
    font-size: var(--font-s);
  }

  &--l {
    flex: 100%;
    width: 100%;
    //padding: 0.7em 3em;
    font-size: var(--font-l);
  }
}

// 2nd: build import
@import "../../../../../../otherSrc/client/we/scss/build";

// our custom component // * NOTE: we can not include partial SCSS files inside the client forlder, atm.

// 3rd: component hooks

@mixin hook-button {
  &--lead {
    @include _button-color(
      var(--c-lead),
      var(--c-text),
      var(--c-text),
      var(--c-lead),
      false
    );
  }

  &--inverse-lead {
    @include _button-color(
      var(--c-inverse),
      var(--c-bg),
      var(--c-lead),
      var(--c-text),
      true
    );
  }

  @include prefix(transition, "color 400ms ease, background 400ms ease");
}

// 4th: component import
@import "../../../../../../otherSrc/client/we/scss/components";

//#DBA800

// 5th: CSS variables declaration/override
body,
:root {
  // --base-margin             : 1px;
  // --base-font-size          : 1.1px;
  --base-font-size: 1px;
  --c-core-lead-1: #ffc67b; // ;#75C594;
  --c-core-lead-2: #ffad62; // ;#75C594;
  --c-core-lead: #ffbf23;
  --c-core-lead-4: #e17a2f;
  --c-core-alt-1: #46beb4;
  --c-core-alt-2: #2ca49a;
  --c-core-alt: #bada55;

  --c-core-inverse: #000000;
  --c-core-bg: #ffffff;
  --c-core-bg-4: #ededed;
  --c-text: #000000;
  --c-comp-lead-2: #d2f61c;
  --c-comp-lead: #9ebd03;

  // not in use
  --c-comp-alt: var(
    --c-warning
  ); // this is the original color. it has poor contrast
  --c-comp-alt-2: #caad00;

  --c-gray-2: #d2d5da;
  --c-gray-1: #fafafa;
  --c-light-gray: #f4f4f4;
  --c-light-gray-2: #e8e6e6;
  --c-dark-gray: #c7c7c7;
  --c-gray-4: #c7c7c7;
  --c-gray-2: #d2d5da;
  --c-faded-lead: rgb(255 191 0 / 41%);
  // --c-gray-2                : #DADAD9;
  //  --c-gray                  : #CBCBCA;
  // --c-gray-4                : #989897;
  // --c-gray-5                : #656564;
  // --c-success               : #5CAC7B;
  // --c-success-inverse       : var(--c-bg);
  // --c-warning               : #C0A300; // darkened compt alt, not in scheme
  // --c-warning-inverse       : var(--c-bg);
  // --c-danger                : #E9596E;
  // --c-danger-inverse        : var(--c-bg);
  // --c-error                 : var(--c-danger);
  // --c-error-inverse         : var(--c-danger-inverse);

  --box-shadow-1: 0 4px 10px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 8px 15px rgba(0, 0, 0, 0.12);
  --box-shadow-3: 0 12px 30px rgba(0, 0, 0, 0.16);
  --box-shadow-4: 0 20px 30px rgba(0, 0, 0, 0.22);
  --box-shadow-5: 0 35px 70px rgba(0, 0, 0, 0.22);
  --box-shadow: var(--box-shadow-3);

  --border-radius-sharp: 5px;

  --font-3xl: 36px;
  --font-2xl: 24px;
}

// 6th: other custom CSS
*,
*::before,
*::after {
  box-sizing: border-box;
}

//firefox
* {
  scrollbar-color: var(--c-lead-2) var(--c-gray);
  scrollbar-width: thin;
}

// chrome
::-webkit-scrollbar-track {
  background-color: var(--c-gray);
}

// 6th: custom css
html,
body {
  background: var(--c-bg);
  font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

  *,
  input,
  button,
  textarea,
  select,
  label,
  p {
    font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }

  button,
  label {
    line-height: 1.6;
  }

  p {
    line-height: 1.5;
  }

  h2 {
    font-weight: 600;
    font-size: var(--font-2xl);
    margin-bottom: var(--font-2xl);

    @media (min-width: 1024px) {
      font-size: var(--font-max);
    }
  }

  h4 {
    @media (max-width: 1024px) {
      font-size: var(--font-l);
    }
  }
}
